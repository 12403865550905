<template>
  <div id="app">
    <MainPage  title="Hotton.ca" gitHub="https://github.com/RyanHotton"/>
    <Copyright author="Ryan Hotton"/>
  </div>
</template>

<script>
import MainPage from './components/MainPage.vue'
import Copyright from './components/Copyright.vue'

export default {
  name: 'app',
  components: {
    MainPage,
    Copyright
  }
}
</script>

<style>
body {
  background-image: url('./assets/back_01-min.jpg');
  background-color: rgba(0,0,0,.8);
  background-blend-mode: multiply;
  background-position-x: 50%;
  background-repeat: no-repeat;
  background-size: cover!important;
  width: 100%;
  height: 100vh;
}

.center {
  text-align: center;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #f7f7f7;
  margin-top: 60px;
}
</style>
