<template>
  <div id="MainPage">
    <div class="contentBody">
      <h1 class="center">Welcome to {{title}}!</h1>
      <a v-bind:href="gitHub" class="btn btn-default btn-git">GitHub Profile</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  props: {
    title: String,
    gitHub: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #MainPage {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }

  .btn-git {
    border: 1px solid #fff!important;
    border-color: #fff!important;
    background: transparent;
    color: #fff;
    background-repeat: no-repeat;
    text-shadow: none;
    border-radius: 0;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
    padding: 10px 20px;
  }

  .btn-git:hover,.btn-git:focus {
    background: #fff;
    color: #000
  }
</style>
