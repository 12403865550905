<template>
  <div id="Copyright">
    Developed By: <span class="author">{{author}}</span>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
  props: {
    author: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #Copyright {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: .75em;
    padding-right: 10px;
    padding-bottom: 10px;
  }
</style>
